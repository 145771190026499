.header {
    display: flex;
    height: 30rem;
    width: 100%;
    background-image: url('../images/bg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: center;
}

.image-title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    height: inherit;
}

.profile-picture {
    height: 15rem;
    border-radius: 100%;
    box-shadow: 2px 2px 5px 0px rgba(65, 65, 65, 0.43);
}

img {
    margin: 1rem;
}

.header-quote {
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    padding: 10px;
}

@media only screen and (max-width: 770px) {
    .header {
        height: 70%;
    }
}