.tech-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    height: 20%;
    width: 100%;
    box-shadow: 4px 5px 10px 2px rgba(78, 78, 78, 0.342);
    overflow-x: auto;
}

.tech-logo-wrapper {
    /* Flex */
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    /* Sizing */
    width: fit-content;
    height: fit-content;
    /* transition: all 0.3s ease; */
}
.tech-logo-wrapper:hover > .tech-logo {
    transform: scale(1.25, 1.25);
    transform: translateY(-1rem);
}

.tech-logo-wrapper:hover > .tooltip-wrapper > .tooltiptext {
    opacity: 1;
}

.tech-bar-wrapper * {
    display: flex;
    box-sizing: border-box;
}

.tech-logo {
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 75px;
    height: 75px;
    transition: all 0.5s ease;
}

.tooltip-wrapper {
    display: flex;
    justify-content: center;
    height: 0px;
    width: fit-content;
    z-index: 1;
}

.tooltiptext {
    /* Sizing */
    height: fit-content;
    width: fit-content;

    /* Color */
    color: #fff;
    background-color: black;

    /* Positioning */
    margin-top: -0.75rem;
    padding: 0.25rem 0.5rem;
    
    /* Border */
    border-radius: 0.25em;

    /* Transition Properties */
    opacity: 0;
    transition: opacity 0.3s ease;
}

/* Images */

.html-logo {
    background-image: url('../images/techpics/html.png');
}

.css-logo {
    background-image: url('../images/techpics/css.png');
}

.javascript-logo {
    background-image: url('../images/techpics/javascript.png');
}

.java-logo {
    background-image: url('../images/techpics/java.png');
}

.bootstrap-logo {
    background-image: url('../images/techpics/bootstrap.png');
}

.postgresql-logo {
    background-image: url('../images/techpics/postgre.png');
}

.sequelize-logo {
    background-image: url('../images/techpics/sequelize.png');
}

.react-logo {
    background-image: url('../images/techpics/react.png');
}

.python-logo {
    background-image: url('../images/techpics/python.png');
}

.flask-logo {
    background-image: url('../images/techpics/flask.png');
}

.angular-logo {
    background-image: url('../images/techpics/angular.png');
}

.vue-logo {
    background-image: url('../images/techpics/vue.png');
}

@media only screen and (max-width: 770px) {
    .tech-bar-wrapper {
        height: 30%;
    }
}