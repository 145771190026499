.beginning-body {
    display: flex;
    padding: 1rem;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
}
.beginning-body > * {
    color: white;
    text-align: center;
}

.ui-card {
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    width: 100%;
    height: 500px;
    max-width: 500px;
    border-radius: 0.5rem;
    box-sizing: border-box;
    box-shadow: 4px 5px 10px 2px rgba(78, 78, 78, 0.342);
}

.fing-background {
    justify-content: center;
    background-image: url('../images/finger-guy.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.fing-quote {
    padding: 12px;
    font-size: 25px;
    font-weight: 500;
    color: white;
    text-align: center;
    background-color: rgba(93, 93, 93, 0.537);
    margin-bottom: 20px;
    cursor: default;
}

.timeline {
    background-color: #324646;
    overflow-y: auto;
    padding: 1rem;
}

.timeline > * {
    width: 95%;
}

.timeline-header {
    font-size: 2rem;
}

.introduction {
    font-style: italic;
    font-size: 1rem;
}

.date {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
}

.event {
    border-top: solid 2px #728d8d;
    padding-top: 0.25rem;
    text-align: left;
}

@media only screen and (max-width: 770px) {
    .beginning-body {
        flex-direction: column;
        align-items: center;
    }

    .ui-card {
        margin-right: 0px;
    }

    .timeline {
        margin-top: 1rem;
    }
}