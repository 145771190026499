.footer-divider {
    height: 5%;
    min-height: fit-content;
    padding: 0.5rem;
    display: flex;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    box-shadow: 4px 0px 10px 0px rgba(78, 78, 78, 0.342);
}

.resume {
    font-size: 35px;
    color: #414c54;
}

.contact-me {
    font-size: 25px;
}

.contact-linked {
    color: rgb(24, 73, 138);
    font-size: 3rem;
}

.contact-twitter {
    color: rgb(56, 120, 204);
    font-size: 3rem;
}

.contact-links {
    color: black;
    font-size: 3rem;
}

.contact-email {
    color: rgb(204, 44, 44);
    font-size: 3rem;
}
