@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url('./styles/header.css');
@import url('./styles/techbar.css');
@import url('./styles/beginnings.css');
@import url('./styles/projects.css');
@import url('./styles/descriptions.css');
@import url('./styles/footer.css');

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  height: fit-content;
  /* min-height: 800px; */
  width: 100%;
}


header * {
  box-sizing: border-box;
  display: flex;
}

.App-header {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: fit-content;
  max-height: 600px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: white;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 100vh;
}